import React, { useEffect, useState } from "react";
import Notes from "../Notes";
import Chat from "../Chat";
import Login from "../Login";
import {
  Alert,
  Button,
  Container,
  Dialog,
  Fab,
  IconButton,
  Slide,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useServices } from "../../util/service-provider";
import { ValidateSessionResponse } from "../../services/user-service";
import ChatIcon from "@mui/icons-material/Chat";
import EditNoteIcon from "@mui/icons-material/EditNote";
import CloseIcon from "@mui/icons-material/Close";
import { useSmallScreenQuery } from "../../util";
import { AccountType } from "../../const/storage";
import BetaListForm from "../BetaListForm";
import Joyride, { CallBackProps, Step } from "react-joyride";

enum ViewComponent {
  NOTES,
  CHAT,
}

const BETA_LIST_TIMER = 30000;

const App = () => {
  const theme = useTheme();
  const { userService } = useServices();

  const [viewHeight, setViewHeight] = useState(`${window.innerHeight}px`);

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    margin: 0,
    padding: 0,
    height: viewHeight,
  };

  const updateHeight = () => {
    setViewHeight(`${window.innerHeight}px`);
  };

  // On mobile, the screen can resize for various reasons. Update height so that
  // components stay in view.
  useEffect(() => {
    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  const currentUser = useSelector((state: RootState) => state.user);

  // Log the user out when they exit the page.
  // This won't always work (e.g. Safari and mobile) because 'beforeunload'
  // won't fire. But a reliable solution is complex and the session restriction
  // only applies to demo accounts, so it's okay if demo use is occasionally
  // blocked for the JWT lifetime (10 min).
  useEffect(() => {
    const logout = () => {
      userService.logout(currentUser.jwt, currentUser.username);
    };

    window.addEventListener("beforeunload", logout);

    return () => {
      window.removeEventListener("beforeunload", logout);
    };
  }, [currentUser, userService]);

  // Pulse check to see if the user's session is still valid.
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (currentUser.jwt) {
      timeout = setTimeout(() => {
        userService
          .validateSession(currentUser.jwt)
          .then((response: ValidateSessionResponse) => {
            if (!response.isValid) {
              clearTimeout(timeout);
              alert("Your session has expired. Please log in again.");
              window.location.reload();
            }
          })
          // Swallow exception since this is just a heartbeat.
          .catch(() => {});
      }, 30000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [currentUser.jwt, userService]);

  const [componentInView, setComponentInView] = useState(ViewComponent.NOTES);

  // Which view to render on small screens.
  const renderComponentInView = () => {
    switch (componentInView) {
      case ViewComponent.NOTES:
        return <Notes />;
      case ViewComponent.CHAT:
        return <Chat />;
      default:
        return <div />;
    }
  };

  const handleSwitchView = () => {
    switch (componentInView) {
      case ViewComponent.NOTES:
        setComponentInView(ViewComponent.CHAT);
        break;
      case ViewComponent.CHAT:
        setComponentInView(ViewComponent.NOTES);
        break;
      default:
        return;
    }
  };

  // Alert to join the beta list for users of the demo account.
  const [displayBetaAlert, setDisplayBetaAlert] = useState(false);
  // useEffect(() => {
  //   // If a demo user logs in, display alert after a minute.
  //   let timeout: NodeJS.Timeout;
  //   if (currentUser.username && currentUser.accountType === AccountType.DEMO) {
  //     timeout = setTimeout(() => {
  //       setDisplayBetaAlert(true);
  //     }, BETA_LIST_TIMER);
  //   }

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [currentUser]);

  const [displayBetaListForm, setDisplayBetaListform] = useState(false);

  const onBetaListDialogClose = () => {
    setDisplayBetaListform(false);
    setDisplayBetaAlert(false);
  };

  // Walkthrough for demo accounts
  const [runWalkthrough, setRunWalkthrough] = useState(false);
  useEffect(() => {
    if (currentUser.username && currentUser.accountType === AccountType.DEMO) {
      setRunWalkthrough(true);
    }
  }, [currentUser]);

  const handleWalkthroughCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses = ["finished", "skipped"];
    if (finishedStatuses.includes(status)) {
      setRunWalkthrough(false);
    }
  };

  const isSmallScreen = useSmallScreenQuery();
  return (
    <>
      {currentUser.username ? (
        <>
          <Joyride
            steps={walkthroughSteps()}
            continuous
            scrollToFirstStep
            showProgress
            showSkipButton
            run={runWalkthrough}
            callback={handleWalkthroughCallback}
            styles={{
              options: {
                primaryColor: theme.palette.secondary.main,
                backgroundColor: theme.palette.secondary.light,
                zIndex: 1000,
              },
              tooltipContent: {
                textAlign: "left",
              },
            }}
            locale={{
              last: "Done",
            }}
          />
          <Container
            className="walkthrough-4"
            maxWidth={false}
            sx={containerStyle}
          >
            {isSmallScreen ? renderComponentInView() : [<Notes />, <Chat />]}
            {isSmallScreen && (
              <Fab
                aria-label="switch-view"
                onClick={handleSwitchView}
                style={{
                  position: "fixed",
                  bottom: "0.75rem",
                  left: "0.75rem",
                  backgroundColor: theme.palette.secondary.light,
                  color: theme.palette.primary.main,
                }}
              >
                {componentInView === ViewComponent.NOTES ? (
                  <ChatIcon className="walkthrough-2" />
                ) : (
                  <EditNoteIcon sx={{ fontSize: 28 }} />
                )}
              </Fab>
            )}

            <Slide in={displayBetaAlert} direction="down" timeout={500}>
              <Alert
                severity="info"
                color="success"
                sx={{
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                }}
                action={
                  <>
                    <Button
                      size="small"
                      sx={{ minWidth: "5rem" }}
                      onClick={() => setDisplayBetaListform(true)}
                    >
                      Sign up
                    </Button>
                    <IconButton
                      size="small"
                      onClick={() => setDisplayBetaAlert(false)}
                      sx={{ ml: 2 }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  </>
                }
              >
                Want your own unrestricted account? Join the beta!
              </Alert>
            </Slide>

            <Dialog
              open={displayBetaListForm}
              onClose={onBetaListDialogClose}
              maxWidth="sm"
              fullWidth
            >
              <BetaListForm />
            </Dialog>
          </Container>
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

const walkthroughSteps = (): Step[] => [
  {
    target: ".walkthrough-1",
    content:
      "Welcome to Dewey! With Dewey, you'll never have to organize or format your notes again. Just scribble away to add to your Library.",
    disableBeacon: true,
  },
  {
    target: ".walkthrough-2",
    content:
      "Here you can talk to Dewey about your notes (or anything else you want). This means there's no need to ever go through the notes yourself when recalling information - he'll find it for you.",
  },
  {
    target: ".walkthrough-3",
    content: (
      <>
        <p>
          This is page 3 - turns out you already wrote some notes about a
          lecture you attended the other day and a friend you made recently. Ask
          about them!
        </p>
        <p>Also...what was Jeff's apartment unit number again?</p>
      </>
    ),
  },
  {
    target: ".walkthrough-4",
    content:
      "Oh last thing, anyone can use this demo account and see its notes and chat history. Please be mindful about what you write.",
  },
];

export default App;
